ion-segment {
    --background: transparent;

    border: 1px solid var(--ion-color-background-contrast);
    border-radius: var(--radius-L);

    ion-segment-button {
        $size: 24px;
        height: $size;
        min-height: $size;
        max-height: $size;

        margin: 2px;

        --background: transparent;
        --background-checked: transparent;
        --background-focused: transparent;
        --background-hover: transparent;

        --color: var(--ion-color-medium);
        --color-checked: var(--ion-color-dark-contrast);

        --indicator-box-shadow: none;
        --indicator-height: 0;

        --margin-top: 0;
        --margin-bottom: 0;
        --margin-start: 0;
        --margin-end: 0;

        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;

        --border-radius: var(--radius-L);

        --border-style: none;

        &::part(indicator) {
            height: 100%;
            padding: 0;
            background-color: var(--ion-color-dark);
            border-radius: var(--radius-L);
        }

        ion-label {
            margin: 0;

            font-size: 0.8rem;
            font-weight: 400;
            text-transform: none;
        }

        &.segment-button-checked {
            ion-label {
                font-weight: 600;
            }
        }
    }
}
