.alert-button {
    &.sc-ion-alert-md {
        color: var(--ion-color-dark);

        &.--solid-button {
            border-radius: var(--radius-L);
            background-color: var(--ion-color-dark);
            color: var(--ion-color-dark-contrast);
        }
    }

    &.sc-ion-alert-ios {
        color: var(--ion-color-dark);

        &.--solid-button {
            font-weight: 600;
        }
    }
}
