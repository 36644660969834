@mixin xxl() {
    font-size: 2.125rem;
    font-weight: 700;
    font-style: normal;
    line-height: 1.15;
    text-decoration: none;
    text-transform: none;
}

@mixin xl() {
    font-size: 1.25rem;
    font-weight: 700;
    font-style: normal;
    line-height: 1.4;
    text-decoration: none;
    text-transform: none;
}

@mixin l() {
    font-size: 1rem;
    font-weight: 700;
    font-style: normal;
    line-height: 1.25;
    text-decoration: none;
    text-transform: none;
}

@mixin m() {
    font-size: 0.75rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25;
    text-decoration: none;
    text-transform: none;
}

@mixin paragraph() {
    font-size: 0.938rem;
    font-weight: 500;
    line-height: 1.4;
    font-style: normal;
    text-decoration: none;
    text-transform: none;
}

@mixin rich-text() {
    $font-size: 0.938rem;
    $line-height: 1.4;
    font-size: $font-size;
    font-weight: 500;
    line-height: $line-height;
    font-style: normal;
    text-decoration: none;
    text-transform: none;

    p {
        &:empty {
            min-height: $line-height * $font-size;
        }
    }
}

@mixin truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin truncate-text-multi-line($lines-to-show) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
