/* stylelint-disable property-no-unknown */
/* stylelint-disable no-descending-specificity */

@import "src/theme/mixins.scss";

ion-input {
    &.sc-ion-input-ios-h,
    &.sc-ion-input-md-h {
        position: relative;

        --color: var(--ion-color-medium-shade);

        --highlight-color: var(--ion-color-medium);
        --highlight-color-valid: var(--ion-color-medium);
        --highlight-color-focused: var(--ion-color-medium);
        --highlight-color-invalid: var(--ion-color-medium);

        --placeholder-color: var(--ion-color-medium);
        --placeholder-opacity: 1;
        --padding-bottom: var(--space-L);

        ion-icon {
            &.--reset-input {
                display: none;

                position: absolute;

                z-index: 2;

                right: 15px;
                top: 50%;

                transform: translateY(calc(-50% - 8px));

                $size: 20px;
                width: $size;
                height: $size;

                color: var(--ion-color-medium-shade);

                --ionicon-stroke-width: 40px;
            }
        }

        &.has-value {
            ion-icon {
                display: block;
            }
        }

        .input-wrapper {
            &.sc-ion-input-ios,
            &.sc-ion-input-md {
                .native-wrapper {
                    &.sc-ion-input-ios,
                    &.sc-ion-input-md {
                        .native-input {
                            &.sc-ion-input-ios,
                            &.sc-ion-input-md {
                                height: 60px;

                                font-size: 1rem;
                                font-weight: 500;
                                line-height: 15.51px;

                                color: var(--color);

                                padding: 20px 40px 20px 20px;

                                background: var(--ion-color-background-tint);

                                border-color: var(--ion-color-background-contrast);
                                border-style: solid;
                                border-width: 0.3px;
                                border-radius: var(--radius-XL);

                                margin: 0;

                                transition:
                                    border-color ease-in 0.15s,
                                    color ease-in 0.15s;

                                &:-webkit-autofill,
                                &:-webkit-autofill:hover,
                                &:-webkit-autofill:focus,
                                &:-webkit-autofill:active {
                                    -webkit-text-fill-color: var(--color) !important;
                                    -webkit-box-shadow: 0 0 0 30px var(--ion-color-background-tint) inset !important;
                                }

                                &:-moz-autofill,
                                &:-moz-autofill:hover,
                                &:-moz-autofill:focus,
                                &:-moz-autofill:active {
                                    text-fill-color: var(--color) !important;
                                    box-shadow: 0 0 0 30px var(--ion-color-background-tint) inset !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.input-label-placement-floating {
            &.sc-ion-input-ios-h,
            &.sc-ion-input-md-h {
                input {
                    &.sc-ion-input-ios,
                    &.sc-ion-input-md {
                        opacity: 1;
                    }
                }
            }

            .label-text-wrapper {
                &.sc-ion-input-ios,
                &.sc-ion-input-md {
                    height: 0;
                    transform: translate3d(20px, 16px, 0) scale(0.65);
                    color: var(--ion-color-medium);
                }
            }

            .input-wrapper {
                .native-wrapper {
                    &.sc-ion-input-ios,
                    &.sc-ion-input-md {
                        .native-input {
                            &.sc-ion-input-ios,
                            &.sc-ion-input-md {
                                padding-top: 25px;
                            }
                        }
                    }
                }
            }
        }

        &.--has-button {
            .input-wrapper {
                &.sc-ion-input-ios,
                &.sc-ion-input-md {
                    .native-wrapper {
                        &.sc-ion-input-ios,
                        &.sc-ion-input-md {
                            ion-button {
                                position: absolute;
                                z-index: 1;
                                right: var(--space-L);

                                font-size: 0.75rem;
                                font-weight: 500;
                                letter-spacing: 0;

                                width: fit-content;
                                height: 35px;

                                margin: 0;

                                --box-shadow: -10px 1px 14px 4px var(--ion-color-background-tint);
                                --border-radius: var(--radius-L);
                                --border-style: none;
                                --padding-start: var(--space-L);
                                --padding-end: var(--space-L);

                                &.--success {
                                    --background: var(--ion-color-success-background);
                                    --background-activated: var(--ion-color-success-background);
                                    --background-focused: var(--ion-color-success-background);
                                    --background-hover: var(--ion-color-success-background);

                                    --color: var(--ion-color-success-shade);
                                    --color-activated: var(--ion-color-success-shade);
                                    --color-focused: var(--ion-color-success-shade);
                                    --color-hover: var(--ion-color-success-shade);
                                }

                                &.--dark {
                                    --background: var(--ion-color-dark);
                                    --background-activated: var(--ion-color-dark);
                                    --background-focused: var(--ion-color-dark);
                                    --background-hover: var(--ion-color-dark);
                                }
                            }
                        }
                    }
                }
            }
        }

        &.ion-invalid.ion-touched.ion-dirty {
            ion-icon {
                color: var(--ion-color-danger-shade);
            }

            .input-wrapper {
                &.sc-ion-input-ios,
                &.sc-ion-input-md {
                    .native-wrapper {
                        &.sc-ion-input-ios,
                        &.sc-ion-input-md {
                            .native-input {
                                &.sc-ion-input-ios,
                                &.sc-ion-input-md {
                                    border-color: var(--ion-color-danger-shade);
                                }
                            }
                        }
                    }
                }
            }
        }

        &[error-message]:not([error-message=""]) {
            &.ion-invalid.ion-touched.ion-dirty {
                margin-bottom: calc(var(--space-L) * 1.75);

                &:after {
                    content: attr(error-message);

                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 15.51px;

                    max-width: 95%;
                    min-width: 30px;

                    position: absolute;
                    z-index: 1;
                    left: var(--space-L);
                    top: var(--space-XXL);

                    color: var(--ion-color-danger-shade);

                    @include truncate-text-multi-line(2);
                }
            }
        }
    }
}
