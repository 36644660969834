ion-checkbox {
    --checkbox-background: var(--ion-color-background);
    --checkbox-background-checked: var(--ion-color-program-background);
    --border-color: var(--ion-color-background-contrast);
    --border-color-checked: var(--ion-color-program-background);
    --border-radius: var(--radius-XXXL);
    --border-style: solid;
    --border-width: 1px;
    --checkmark-color: var(--ion-color-program-foreground);
    --checkmark-width: 1px;
    --transition: background-color ease-in 0.15s;
    --size: 1.5rem;

    &.ng-touched.ng-invalid {
        --checkbox-background: var(--ion-color-danger-background);
        --checkbox-background-checked: var(--ion-color-danger);
        --border-color: var(--ion-color-danger);
        --border-color-checked: var(--ion-color-danger);
    }
}
