ion-header {
    &.header-md,
    &.header-ios {
        box-shadow: none;
    }

    .segments {
        background-color: var(--ion-background-color);
        padding: 22px 16px 14px;

        &::after {
            content: "";

            width: 100%;
            height: 8px;

            position: absolute;
            left: 0;
            bottom: -8px;

            background: linear-gradient(to bottom, var(--ion-background-color) 0%, transparent 100%);
        }
    }
}
