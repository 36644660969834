ion-footer {
    &.footer-md,
    &.footer-ios {
        display: flex;
        flex-direction: column;
        gap: var(--space-M);

        box-shadow: none;
        background: var(--ion-background-color);
        padding: var(--space-L);
    }
}
