ion-toolbar {
    $balance-margin: 0 auto;

    --background: transparent;
    --border-style: none;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --min-height: calc(min(100vw, 450px) / 3.4);

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    background-color: var(--ion-background-color);

    .back-button {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__logo {
            max-width: 120px;
            max-height: 80px;
            width: auto;
            height: auto;
        }
    }

    .balance {
        margin: var(--balance-margin, $balance-margin);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: var(--space-S);

        &__title,
        &__subtitle {
            color: #fff;
        }

        &__title {
            font-size: 3.75rem;
            font-weight: 600;
            line-height: 64px;
        }
    }
}
