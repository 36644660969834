ion-button {
    --border-radius: var(--radius-XXL);
    --padding-end: var(--space-L);
    --padding-start: var(--space-L);
    --box-shadow: none;

    width: 100%;
    height: 50px;
    margin: 0;

    font-size: 16px;
    font-weight: 500;
    line-height: 17.9px;
    text-transform: none;

    &.program-button {
        --background: var(--ion-color-program-background);
        --background-hover: var(--ion-color-program-background);
        --background-activated: var(--ion-color-program-background);
        --background-focused: var(--ion-color-program-background);
        --color: var(--ion-color-program-foreground);

        ion-icon {
            --color: var(--ion-color-program-foreground);
        }
    }
}
