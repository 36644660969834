// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "./mixins.scss";

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #8bafcc;
    --ion-color-primary-rgb: 139, 175, 204;
    --ion-color-primary-tint: #a3bfd7;
    --ion-color-primary-shade: #6b8da8;
    --ion-color-primary-contrast: #fcfcfc;
    --ion-color-primary-contrast-rgb: 252, 252, 252;
    --ion-color-primary-background: #dce7ef;
    --ion-color-primary-background-rgb: 220, 231, 239;

    /** secondary **/
    --ion-color-secondary: #3e8cee;
    --ion-color-secondary-rgb: 62, 140, 238;
    --ion-color-secondary-shade: #1368d3;
    --ion-color-secondary-tint: #77aef3;
    --ion-color-secondary-contrast: #fcfcfc;
    --ion-color-secondary-contrast-rgb: 252, 252, 252;
    --ion-color-secondary-background: #d6e7fc;
    --ion-color-secondary-background-rgb: 214, 231, 252;

    /** success **/
    --ion-color-success: #65c443;
    --ion-color-success-rgb: 101, 196, 67;
    --ion-color-success-shade: #47902d;
    --ion-color-success-tint: #82d067;
    --ion-color-success-contrast: #fcfcfc;
    --ion-color-success-contrast-rgb: 252, 252, 252;
    --ion-color-success-background: #d0edc6;
    --ion-color-success-background-rgb: 208, 237, 198;

    /** warning **/
    --ion-color-warning: #e8a95f;
    --ion-color-warning-rgb: 232, 169, 95;
    --ion-color-warning-shade: #c4771d;
    --ion-color-warning-tint: #eab271;
    --ion-color-warning-contrast: #fcfcfc;
    --ion-color-warning-contrast-rgb: 252, 252, 252;
    --ion-color-warning-background: #f7e5ce;
    --ion-color-warning-background-rgb: 247, 229, 206;

    /** danger **/
    --ion-color-danger: #eb5757;
    --ion-color-danger-rgb: 235, 87, 87;
    --ion-color-danger-shade: #b61616;
    --ion-color-danger-tint: #f08484;
    --ion-color-danger-contrast: #fcfcfc;
    --ion-color-danger-contrast-rgb: 252, 252, 252;
    --ion-color-danger-background: #f8cccc;
    --ion-color-danger-background-rgb: 248, 204, 204;

    /** dark **/
    --ion-color-dark: #333333;
    --ion-color-dark-rgb: 51, 51, 51;
    --ion-color-dark-shade: #0f0f0f;
    --ion-color-dark-tint: #545454;
    --ion-color-dark-contrast: #fcfcfc;
    --ion-color-dark-contrast-rgb: 252, 252, 252;
    --ion-color-dark-background: #cccccc;
    --ion-color-dark-background-rgb: 204, 204, 204;

    /** medium **/
    --ion-color-medium: #a5a5a5;
    --ion-color-medium-rgb: 165, 165, 165;
    --ion-color-medium-shade: #6e6e6e;
    --ion-color-medium-tint: #bfbfbf;
    --ion-color-medium-contrast: #f5f5f5;
    --ion-color-medium-contrast-rgb: 245, 245, 245;
    --ion-color-medium-background: #e8e8e8;
    --ion-color-medium-background-rgb: 232, 232, 232;

    /** light **/
    --ion-color-light: #fafafa;
    --ion-color-light-rgb: 250, 250, 250;
    --ion-color-light-shade: #ededed;
    --ion-color-light-tint: #ffffff;
    --ion-color-light-contrast: #949494;
    --ion-color-light-contrast-rgb: 148, 148, 148;
    --ion-color-light-background: #fdfdfd;
    --ion-color-light-background-rgb: 253, 253, 253;

    /** background **/
    --ion-color-background: #fafafa;
    --ion-color-background-rgb: 255, 255, 255;
    --ion-color-background-shade: #ededed;
    --ion-color-background-tint: #f7f7f7;
    --ion-color-background-contrast: #e0e0e0;
    --ion-color-background-contrast-rgb: 191, 191, 191;

    --ion-background-color: #fafafa;

    // layout
    --space-XS: 2px;
    --space-S: 4px;
    --space-M: 8px;
    --space-L: 16px;
    --space-XL: 32px;
    --space-XXL: 64px;

    --radius-S: 2px;
    --radius-M: 4px;
    --radius-L: 8px;
    --radius-XL: 16px;
    --radius-XXL: 50px;
    --radius-XXXL: 100px;
}

html {
    /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
    --ion-dynamic-font: var(--ion-default-dynamic-font);
    --ion-font-family: -apple-system, BlinkMacSystemFont, ‘Segoe UI’, Roboto, ‘Helvetica Neue’, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    margin: 0;
}

a {
    @include paragraph();
    color: var(--ion-color-dark);
    text-decoration: underline;
}

.--xxl {
    @include xxl();
    color: var(--ion-color-dark);
}

.--xl {
    @include xl();
    color: var(--ion-color-dark);
}
.--l {
    @include l();
    color: var(--ion-color-dark);
}
.--m {
    @include m();
    color: var(--ion-color-dark);
}
.--paragraph {
    @include paragraph();
    color: var(--ion-color-dark);
}

.--rich-text {
    @include rich-text();
    color: var(--ion-color-dark);
}

.--is-clickable {
    cursor: pointer;
}
